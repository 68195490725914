@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    @apply font-inter;
  }
  html {
    scroll-behavior: smooth;
    background-color: black;
  }